import * as React from "react";
import { useState } from "react";
import {
  StyleSheet,
  View,
  Image,
  TouchableHighlight,
  Text,
  TextInput,
  ScrollView,
  TouchableWithoutFeedback,
} from "react-native";
import ReactPlayer from "react-player";
import { findDOMNode } from "react-dom";
import screenfull from "screenfull";
import Modal from "react-native-modal";
import { FontAwesome5, MaterialIcons } from "@expo/vector-icons";
import Slider from "@react-native-community/slider";
import { Amplify, Auth, Storage } from "aws-amplify";
import { withAuthenticator, AmplifyTheme } from "aws-amplify-react-native";
import _ from "lodash";
import { Tooltip } from "@material-ui/core";

import { createViewPortConfig } from "react-native-responsive-view-port";
const { vw, vh } = createViewPortConfig();

import awsconfig from "./src/aws-exports";

Amplify.configure(awsconfig);

const TuneRepsContainer = Object.assign({}, AmplifyTheme.container, {
  flex: 1,
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-around",
  paddingTop: 20,
  width: "100%",
  backgroundColor: "#000000",
});
const TuneRepsSectionHeaderText = Object.assign(
  {},
  AmplifyTheme.sectionHeaderText,
  {
    color: "#FFFFFF",
    fontSize: 20 * vh,
    fontWeight: "500",
  }
);
const TuneRepsSectionFooterLink = Object.assign(
  {},
  AmplifyTheme.sectionFooterLink,
  {
    fontSize: 14 * vh,
    color: "#4CFF4C",
    alignItems: "baseline",
    textAlign: "center",
  }
);
const TuneRepsSectionFooterLinkDisabled = Object.assign(
  {},
  AmplifyTheme.TuneRepsSectionFooterLinkDisabled,
  {
    fontSize: 14 * vh,
    color: "#4CFF4C80",
    alignItems: "baseline",
    textAlign: "center",
  }
);
const TuneRepsButton = Object.assign({}, AmplifyTheme.button, {
  backgroundColor: "#4CFF4C",
  alignItems: "center",
  padding: 16,
});
const TuneRepsButtonDisabled = Object.assign({}, AmplifyTheme.buttonDisabled, {
  backgroundColor: "#4CFF4C80",
  alignItems: "center",
  padding: 16,
});
const TuneRepsButtonText = Object.assign({}, AmplifyTheme.buttonText, {
  color: "#000000",
  fontSize: 14 * vh,
  fontWeight: "540",
});
const TuneRepsInput = Object.assign({}, AmplifyTheme.input, {
  padding: 16,
  borderWidth: 1,
  borderRadius: 3,
  borderColor: "#FFFFFF",
  color: "#FFFFFF",
});
const TuneRepsPhoneInput = Object.assign({}, AmplifyTheme.phoneInput, {
  flex: 2,
  padding: 16,
  borderWidth: 1,
  borderRadius: 3,
  borderColor: "#FFFFFF",
  color: "#FFFFFF",
});

const TuneRepsErrorRowText = Object.assign({}, AmplifyTheme.errorRowText, {
  marginLeft: 10,
  color: "#FFFFFF",
});

const MyTheme = Object.assign({}, AmplifyTheme, {
  container: TuneRepsContainer,
  sectionHeaderText: TuneRepsSectionHeaderText,
  sectionFooterLink: TuneRepsSectionFooterLink,
  sectionFooterLink: TuneRepsSectionFooterLinkDisabled,
  button: TuneRepsButton,
  buttonDisabled: TuneRepsButtonDisabled,
  buttonText: TuneRepsButtonText,
  input: TuneRepsInput,
  phoneInput: TuneRepsPhoneInput,
  errorRowText: TuneRepsErrorRowText,
});

function getPlaybackRateFPS(recordingTempo, audioBPM, originalFrameRate) {
  var playbackRateFPS = 0.0;
  var optimizedRecordingTempo = 0.0;
  var minimum = audioBPM / 1.5;
  var maximum = audioBPM * 1.5;
  if (recordingTempo > minimum && recordingTempo < maximum) {
    if (
      (recordingTempo / 2 > minimum && recordingTempo / 2 < maximum) ||
      (recordingTempo * 2 > minimum && recordingTempo * 2 < maximum)
    ) {
      if (recordingTempo / 2 > minimum && recordingTempo / 2 < maximum) {
        if (recordingTempo / 2 < recordingTempo) {
          if (minimum / (recordingTempo / 2) < recordingTempo / maximum) {
            optimizedRecordingTempo = recordingTempo / 2;
          } else {
            optimizedRecordingTempo = recordingTempo;
          }
        } else {
          if (minimum / recordingTempo < recordingTempo / 2 / maximum) {
            optimizedRecordingTempo = recordingTempo;
          } else {
            optimizedRecordingTempo = recordingTempo / 2;
          }
        }
      } else {
        if (recordingTempo * 2 < recordingTempo) {
          if (minimum / (recordingTempo * 2) < recordingTempo / maximum) {
            optimizedRecordingTempo = recordingTempo * 2;
          } else {
            optimizedRecordingTempo = recordingTempo;
          }
        } else {
          if (minimum / recordingTempo < (recordingTempo * 2) / maximum) {
            optimizedRecordingTempo = recordingTempo;
          } else {
            optimizedRecordingTempo = recordingTempo * 2;
          }
        }
      }
    } else {
      optimizedRecordingTempo = recordingTempo;
    }
  } else {
    if (recordingTempo / 2 > minimum && recordingTempo / 2 < maximum) {
      optimizedRecordingTempo = recordingTempo / 2;
    } else {
      if (recordingTempo * 2 > minimum && recordingTempo * 2 < maximum) {
        optimizedRecordingTempo = recordingTempo * 2;
      } else {
        if (recordingTempo / 3 > minimum && recordingTempo / 3 < maximum) {
          optimizedRecordingTempo = recordingTempo / 3;
        } else {
          if (recordingTempo * 3 > minimum && recordingTempo * 3 > maximum) {
            optimizedRecordingTempo = recordingTempo * 3;
          } else {
            var count = 4;
            while (
              !(
                recordingTempo / count > minimum &&
                recordingTempo / count < maximum
              ) &&
              !(
                recordingTempo * count > minimum &&
                recordingTempo * count < maximum
              )
            ) {
              count++;
            }
            if (
              recordingTempo / count > minimum &&
              recordingTempo / count < maximum
            ) {
              optimizedRecordingTempo = recordingTempo / count;
            } else {
              optimizedRecordingTempo = recordingTempo * count;
            }
          }
        }
      }
    }
  }
  playbackRateFPS = (originalFrameRate * audioBPM) / optimizedRecordingTempo;
  return playbackRateFPS;
}

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log("error signing out: ", error);
  }
}

function App() {
  const [isTotalModalVisisble, setTotalModalVisible] = useState(false);
  const toggleTotalModal = () => {
    setTotalModalVisible(!isTotalModalVisisble);
  };

  const [isBPMModalVisible, setBPMModalVisible] = useState(false);
  const toggleBPMModal = () => {
    setBPMModalVisible(!isBPMModalVisible);
  };

  const [number, onChangeNumber] = React.useState(126);
  const [frameRate, onChangeFrameRate] = React.useState(1.0);

  const [playbackState, onChangePlaybackState] = React.useState(false);
  const togglePlaybackState = () => {
    onChangePlaybackState((current) => !current);
  };

  const [muteState, onChangeMuteState] = React.useState(false);
  const toggleMuteState = () => {
    onChangeMuteState((current) => !current);
  };

  const [repeatState, onChangeRepeatState] = React.useState(false);
  const toggleRepeatState = () => {
    onChangeRepeatState((current) => !current);
  };

  var reactPlayer = null;
  const setReactPlayerRef = (element) => {
    reactPlayer = element;
  };
  const onClickFullscreen = () => {
    screenfull.request(findDOMNode(reactPlayer));
  };
  const onClickNormalScreen = () => {
    screenfull.exit(findDOMNode(reactPlayer));
  };

  const [isLoadModalVisible, setLoadModalVisible] = React.useState(false);
  const toggleLoadModal = () => {
    setLoadModalVisible(!isLoadModalVisible);
  };

  const [categories, setCategories] = React.useState([]);
  const listCategories = () => {
    Storage.list("")
      .then((result) => {
        setCategories(
          _.uniq(result.map((file) => file.key.split(" - ")[1]))
            .sort()
            .map((category) => (
              <TouchableHighlight
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  height: 32 * vh,
                  width: 540 * vw,
                  borderRadius: 64,
                  margin: 8,
                  backgroundColor: "#FFFFFF40",
                }}
                underlayColor={"#4CFF4C"}
                onPress={() => {
                  setCategories([]);
                  listExerciseVideos(category);
                }}
              >
                <Text style={styles.categoryUnselectedText}>{category}</Text>
              </TouchableHighlight>
            ))
        );
      })
      .catch((err) => console.log(err));
  };

  const [exerciseVideos, setExerciseVideos] = React.useState([]);
  const [blob, setBlob] = React.useState(null);
  const [videoLoading, setVideoLoading] = React.useState(false);
  const [videoTitle, setVideoTitle] = React.useState("");
  const listExerciseVideos = (selectedCategory) => {
    Storage.list("")
      .then((result) => {
        setExerciseVideos(
          result
            .filter((file) => file.key.includes(selectedCategory))
            .map((file) => file.key.split(" - ")[2])
            .sort()
            .map((exerciseVideo) => (
              <TouchableHighlight
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                  height: 32 * vh,
                  width: 540 * vw,
                  borderRadius: 64,
                  margin: 8 * vw,
                  backgroundColor: "#FFFFFF40",
                }}
                underlayColor={"#4CFF4C"}
                onPress={() => {
                  setLoadModalVisible(false);
                  Storage.get(
                    result.filter((file) => file.key.includes(exerciseVideo))[0]
                      .key,
                    {
                      download: true,
                      progressCallback(progress) {
                        setVideoTitle(
                          ((progress.loaded / progress.total) * 100).toFixed(
                            0
                          ) + "% LOADED"
                        );
                      },
                    }
                  ).then((download) => {
                    setBlob(URL.createObjectURL(download.Body));
                    setVideoLoading(true);
                    if (exerciseVideo.split(".")[1] === "3") {
                      setVideoTitle(
                        exerciseVideo.split(".")[0] +
                          exerciseVideo.split(".")[1]
                      );
                    } else {
                      setVideoTitle(exerciseVideo.split(".")[0]);
                    }
                    setExerciseVideos([]);
                    this.forceUpdate();
                  });
                }}
              >
                <Text style={styles.categoryUnselectedText}>
                  {exerciseVideo}
                </Text>
              </TouchableHighlight>
            ))
        );
      })
      .catch((err) => console.log(err));
  };

  const [isTuneRepsDropZoneModalVisible, setTuneRepsDropZoneModalVisible] =
    React.useState(false);
  const toggleTuneRepsDropZoneModal = () => {
    setTuneRepsDropZoneModalVisible(!isTuneRepsDropZoneModalVisible);

    if (!isTuneRepsDropZoneModalVisible) {
      setDroppedFiles([]);
      setCancelDisabled(false);
      setUploadDisabled(false);
      setFinishDisabled(true);
    }
  };
  const [droppedFiles, setDroppedFiles] = React.useState([]);

  const [progress, setProgress] = React.useState("");

  const [isCancelDisabled, setCancelDisabled] = React.useState(false);
  const [isUploadDisabled, setUploadDisabled] = React.useState(false);
  const [isFinishDisabled, setFinishDisabled] = React.useState(true);

  function cancelVideoUpload() {
    window.location.reload();
  }

  function uploadVideo(file, loaded, total) {
    try {
      const upload = Storage.put(file.name, file, {
        resumable: true,
        completeCallback: (event) => {
          setCancelDisabled(true);
          setUploadDisabled(true);
          setFinishDisabled(false);
        },
        progressCallback: (status) => {
          setProgress(
            loaded +
              " of " +
              total +
              " Videos Uploading... " +
              ((status.loaded / status.total) * 100).toFixed(0) +
              "% of Current File Uploaded"
          );
        },
        errorCallback: (err) => {
          alert("Unexpected error while uploading", err);
        },
      });
    } catch (err) {
      alert("Error uploading file:" + " " + err.toString());
    }
  }

  const [totalSeconds, setTotalSeconds] = React.useState(0.0);
  const [playedSeconds, setPlayedSeconds] = React.useState(0.0);
  const [loadedSeconds, setLoadedSeconds] = React.useState(0.0);
  const [totalDuration, setTotalDuration] = React.useState("00:00");
  const [playedDuration, setPlayedDuration] = React.useState("00:00");
  const [loadedDuration, setLoadedDuration] = React.useState("00:00");
  const [isCountingUp, setIsCountingUp] = React.useState(true);
  const toggleIsCountingUp = () => {
    setIsCountingUp((current) => !current);
  };

  const resetTotal = () => {
    setTotalSeconds(0.0);
  };

  const displayDuration = (durationInSeconds, type, toggle) => {
    var result = "";
    var total = durationInSeconds;
    var minutes = 0.0;
    var seconds = 0.0;

    if (!toggle && type === "played") total = loadedSeconds - durationInSeconds;

    minutes = Math.floor(total / 60.0);
    seconds = Math.floor(total - minutes * 60.0);

    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    result = minutes + ":" + seconds;

    if (type == "total") {
      setTotalDuration(result);
    } else if (type === "played") {
      setPlayedDuration(result);
    } else if (type === "loaded") {
      setLoadedDuration(result);
    }
  };

  const [syncState, setSyncState] = React.useState(false);
  const toggleSyncState = () => {
    setSyncState((current) => !current);
  };
  const syncBPM = async () => {
    let user = await Auth.currentUserInfo();
    if (syncState) {
      Storage.get(user.username + ".txt", {
        contentType: "text/plain",
        level: "private",
        download: true,
        cacheControl: "no-cache",
      }).then((file) => {
        file.Body.text().then((bpm) => {
          onChangeNumber(bpm);
          onChangeFrameRate(
            getPlaybackRateFPS(126, parseFloat(bpm), 30) / 30.0
          );
          console.log("BPM: " + bpm);
          console.log("Number: " + number);
        });
      });
    }
  };

  const [isSliding, setIsSliding] = React.useState(false);
  const updateSlider = (progress) => {
    setPlayedSeconds(progress.playedSeconds);
    displayDuration(progress.playedSeconds, "played", isCountingUp);

    syncBPM();

    if (videoLoading) {
      setLoadedSeconds(progress.loadedSeconds);
      displayDuration(progress.loadedSeconds, "loaded", isCountingUp);
      setVideoLoading(false);
    } else {
      setTotalSeconds((current) => current + 1);
      displayDuration(totalSeconds, "total", true);
    }
  };
  const seekSlider = (value) => {
    if (isSliding) {
      reactPlayer.seekTo(value, "seconds");
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.leftPanel}>
        <View>
          <Image style={styles.image} source={require("./assets/logo.png")} />
          <Text style={styles.displayText}>{videoTitle}</Text>
        </View>
        <View>
          <view style={{ flex: 1 }}>
            <TouchableHighlight
              style={styles.button}
              underlayColor="#4DFF6A"
              onPress={() => {
                listCategories();
                toggleLoadModal();
              }}
            >
              <Text style={styles.buttonTitle}>LOAD</Text>
            </TouchableHighlight>
            <Text style={styles.hiddenText}>null</Text>
            <Modal isVisible={isLoadModalVisible}>
              <View
                style={{
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 64,
                  backgroundColor: "#000000",
                }}
              >
                <Text style={styles.title}>Video Categories</Text>
                <ScrollView
                  style={{
                    justifyContent: "flex-start",
                    alignItems: "center",
                    marginTop: 54 * vh,
                    height: "100%",
                    width: "100%",
                    borderRadius: 64,
                    backgroundColor: "#000000",
                  }}
                >
                  {categories}
                  {exerciseVideos}
                </ScrollView>
                <TouchableHighlight
                  style={styles.button}
                  underlayColor="#4CFF4C"
                  onPress={() => {
                    setCategories([]);
                    setExerciseVideos([]);
                    toggleLoadModal();
                  }}
                >
                  <Text style={styles.buttonTitle}>BACK</Text>
                </TouchableHighlight>
              </View>
            </Modal>
          </view>
        </View>
        <View>
          <TouchableHighlight
            style={styles.button}
            underlayColor="#4CFF4C"
            onPress={signOut}
          >
            <Text style={styles.buttonTitle}>SIGN OUT</Text>
          </TouchableHighlight>
          <Text style={styles.hiddenText}>null</Text>
        </View>
      </View>
      <View style={styles.centerPanel}>
        <TouchableWithoutFeedback
          style={{
            position: "absolute",
            left: 0,
            padding: 20,
            backgroundColor: "green",
          }}
          onPress={() => {
            onClickNormalScreen();
          }}
        >
          <ReactPlayer
            onProgress={(progress) => updateSlider(progress)}
            config={{
              file: {
                attributes: {
                  onContextMenu: (e) => e.preventDefault(),
                  controlsList: "nodownload",
                },
              },
            }}
            ref={setReactPlayerRef}
            height={540 * vh}
            width={540 * vw}
            playing={playbackState}
            playbackRate={frameRate}
            controls={false}
            volume={1}
            muted={muteState}
            loop={repeatState}
            url={blob}
          />
        </TouchableWithoutFeedback>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            width: 540 * vw,
            height: 80 * vh,
          }}
        >
          <Slider
            style={{ width: 540 * vw, height: 32 * vh }}
            minimumValue={0.0}
            maximumValue={loadedSeconds}
            value={playedSeconds}
            thumbTintColor="#4CFF4C"
            minimumTrackTintColor="#4CFF4C"
            maximumTrackTintColor="#FFFFFF40"
            onValueChange={(value) => seekSlider(value)}
            onSlidingStart={() => setIsSliding(true)}
            onSlidingComplete={() => setIsSliding(false)}
          />
          <View style={styles.playbackControls}>
            <Tooltip title="Playback">
              <FontAwesome5.Button
                resizeMode="contain"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: 48 * vh,
                  width: 48 * vw,
                }}
                name={playbackState ? "pause" : "play"}
                color="#4CFF4C"
                backgroundColor="#000000"
                onPress={togglePlaybackState}
                size={18}
              />
            </Tooltip>
            <Tooltip title="Sound">
              <MaterialIcons.Button
                resizeMode="contain"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 48 * vh,
                  width: 48 * vw,
                }}
                name={muteState ? "volume-up" : "volume-up"}
                color={muteState ? "#FFFFFF80" : "#4CFF4C"}
                backgroundColor="#000000"
                onPress={toggleMuteState}
                size={27}
              />
            </Tooltip>
            <Tooltip title="Fullscreen">
              <FontAwesome5.Button
                resizeMode="contain"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  height: 48 * vh,
                  width: 48 * vw,
                }}
                name="expand"
                color="#FFFFFF80"
                backgroundColor="#000000"
                onPress={onClickFullscreen}
                size={18}
              />
            </Tooltip>
            <Tooltip title="Repeat">
              <MaterialIcons.Button
                resizeMode="contain"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 48 * vh,
                  width: 48 * vw,
                }}
                name={repeatState ? "repeat" : "repeat"}
                color={repeatState ? "#4CFF4C" : "#FFFFFF80"}
                backgroundColor="#000000"
                onPress={toggleRepeatState}
                size={27}
              />
            </Tooltip>
            <Tooltip title="TuneReps Sync Mode">
              <FontAwesome5.Button
                resizeMode="contain"
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  justifyContent: "center",
                  height: 48 * vh,
                  width: 48 * vw,
                }}
                name={syncState ? "music" : "music"}
                color={syncState ? "#4CFF4C" : "#FFFFFF80"}
                backgroundColor="#000000"
                onPress={toggleSyncState}
                size={18}
              />
            </Tooltip>
            {/*
            <View>
              <view style={{flex: 1, backgroundColor: '#000000'}}>
                <MaterialIcons.Button style={styles.playbackButton} name='upload-file' color='#4CFF4C' backgroundColor='#000000' onPress={toggleTuneRepsDropZoneModal}/>
                <Modal isVisible={isTuneRepsDropZoneModalVisible}>
                <View style={{ flex: 1, justifyContent: 'space-between', alignItems: 'center', borderRadius: 64, backgroundColor: '#000000' }}>
                    <Text style={styles.title}>Drag 'n' Drop Video File</Text>
                    <Dropzone accept='video/*' maxFiles={1}
                      onDrop={acceptedFiles => setDroppedFiles(acceptedFiles)}>
                      {({getRootProps, getInputProps}) => {
                        const fileNames = droppedFiles.map((file) =>
                          <Text style={{color: '#FFFFFF80'}}>{file.name}</Text>
                        )
                        return (
                        <section>
                          <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <ScrollView>{fileNames.length == 0 ? <Text style={{color: '#FFFFFF80'}}>Drag 'n' drop a video file here, or click to select video file"</Text> : fileNames}</ScrollView>
                          </div>
                        </section>
                      )}}
                    </Dropzone>
                    <Text style={{color: '#FFFFFF80'}}>{progress}</Text>
                    <View style={styles.loadingControls}>
                      <TouchableHighlight 
                        style={styles.button}
                        underlayColor='#4CFF4C'
                        disabled={isCancelDisabled}
                        onPress={cancelVideoUpload}>
                        <Text style={styles.buttonTitle}>CANCEL</Text>
                      </TouchableHighlight>
                      <TouchableHighlight
                        style={styles.button}
                        underlayColor='#4CFF4C'
                        disabled={isUploadDisabled}
                        onPress={() => {
                          var loaded = 1
                          var total = droppedFiles.length
                          setCancelDisabled(false)
                          setUploadDisabled(true)
                          setFinishDisabled(true)
                          droppedFiles.map(file => {
                            uploadVideo(file, loaded, total)
                            loaded++
                          })
                        }}>
                        <Text style={styles.buttonTitle}>UPLOAD</Text>
                      </TouchableHighlight>
                      <TouchableHighlight 
                        style={styles.button}
                        underlayColor='#4CFF4C'
                        disabled={isFinishDisabled}
                        onPress={() => {
                          setProgress('')
                          toggleTuneRepsDropZoneModal()
                        }}>
                        <Text style={styles.buttonTitle}>FINISH</Text>
                      </TouchableHighlight>
                    </View>
                  </View>
                </Modal>
              </view>
            </View>
*/}
          </View>
        </View>
      </View>
      <View style={styles.rightPanel}>
        <View>
          <View style={{ flex: 1, flexDirection: "center" }}>
            <TouchableHighlight
              style={styles.button}
              underlayColor="#4CFF4C"
              onPress={() => {
                toggleTotalModal();
              }}
            >
              <Text style={styles.buttonTitle}>TOTAL</Text>
            </TouchableHighlight>
            <Modal isVisible={isTotalModalVisisble}>
              <View
                style={{
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 64,
                  backgroundColor: "#000000",
                }}
              >
                <Text style={styles.title}>Reset Total</Text>
                <Text style={styles.displayText}>
                  Are you sure you want to reset the total time exercising?
                </Text>
                <TouchableHighlight
                  style={styles.button}
                  underlayColor="#4CFF4C"
                  onPress={() => {
                    resetTotal();
                    toggleTotalModal();
                  }}
                >
                  <Text style={styles.buttonTitle}>YES</Text>
                </TouchableHighlight>
                <TouchableHighlight
                  style={styles.button}
                  underlayColor="#4CFF4C"
                  onPress={() => {
                    toggleTotalModal();
                  }}
                >
                  <Text style={styles.buttonTitle}>NO</Text>
                </TouchableHighlight>
              </View>
            </Modal>
          </View>
          <Text style={styles.displayText}>{totalDuration}</Text>
        </View>
        <View style={{ flexDirection: "center" }}>
          <TouchableHighlight
            style={styles.button}
            underlayColor="#4CFF4C"
            onPress={() => {
              if (loadedSeconds !== 0.0) {
                displayDuration(playedSeconds, "played", !isCountingUp);
                toggleIsCountingUp();
              }
            }}
          >
            <Text style={styles.buttonTitle}>DURATION</Text>
          </TouchableHighlight>
          <Text style={styles.displayText}>
            {playedDuration + " / " + loadedDuration}
          </Text>
        </View>
        <View>
          <View style={{ flex: 1, flexDirection: "center" }}>
            <TouchableHighlight
              style={styles.button}
              underlayColor="#4CFF4C"
              onPress={toggleBPMModal}
            >
              <Text style={styles.buttonTitle}>BPM</Text>
            </TouchableHighlight>
            <Modal isVisible={isBPMModalVisible}>
              <View
                style={{
                  flex: 1,
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderRadius: 64,
                  backgroundColor: "#000000",
                }}
              >
                <Text style={styles.title}>Enter BPM</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={(text) => {
                    text.replace(/[^0-9^.]/, "");
                    if (!isNaN(text)) {
                      onChangeNumber(text);
                    } else {
                      text.replace(/[A-Za-z0-9.]/, "");
                    }
                  }}
                  value={number}
                  placeholder="New BPM Value"
                  keyboardType="numeric"
                />
                <TouchableHighlight
                  style={styles.button}
                  underlayColor="#4CFF4C"
                  onPress={() => {
                    if (!isNaN(parseFloat(number))) {
                      toggleBPMModal();
                      onChangeFrameRate(
                        getPlaybackRateFPS(126, parseFloat(number), 30) / 30.0
                      );
                    }
                  }}
                >
                  <Text style={styles.buttonTitle}>ENTER</Text>
                </TouchableHighlight>
              </View>
            </Modal>
          </View>
          <Text style={styles.displayText}>{number}</Text>
        </View>
      </View>
    </View>
  );
}

export default withAuthenticator(App, false, [], null, MyTheme);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#000000",
  },
  leftPanel: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    width: "25%",
    backgroundColor: "#000000",
  },
  centerPanel: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    width: "50%",
    backgroundColor: "#000000",
  },
  rightPanel: {
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    width: "25%",
    backgroundColor: "#000000",
  },
  playbackControls: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 48 * vh,
    width: 540 * vw,
    backgroundColor: "#000000",
  },
  loadingControls: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
    borderRadius: 64,
    height: 54 * vh,
    width: "100%",
    backgroundColor: "#000000",
  },
  image: {
    width: 188 * vw,
    height: 188 * vh,
    resizeMode: "contain",
  },
  button: {
    height: 54 * vh,
    width: 132 * vw,
    marginTop: 70 * vh,
    marginBottom: 16 * vh,
    justifyContent: "center",
    textAlign: "center",
    backgroundColor: "#FFFFFF40",
    borderRadius: 64,
    borderWidth: 1,
    borderColor: "#000000",
    overflow: "hidden",
  },
  buttonTitle: {
    justifyContent: "center",
    textAlign: "center",
    fontSize: 18 * vh,
    color: "#FFFFFF",
  },
  hiddenText: {
    justifyContent: "flex-end",
    textAlign: "center",
    color: "#000000",
    fontSize: 18 * vh,
  },
  displayText: {
    justifyContent: "flex-end",
    marginBottom: 54,
    textAlign: "center",
    color: "#4CFF4C",
    fontSize: 18 * vh,
  },
  input: {
    height: 40 * vh,
    margin: 12 * vw,
    borderWidth: 1,
    borderRadius: 64,
    borderColor: "#FFFFFF80",
    marginTop: 54 * vh,
    padding: 10 * vw,
    textAlign: "center",
    fontSize: 18 * vh,
    color: "#FFFFFF80",
  },
  title: {
    textAlign: "center",
    fontSize: 48 * vh,
    color: "#FFFFFF",
  },
  playbackButton: {
    flexDirection: "row",
    justifyContent: "center",
    height: 24 * vh,
    width: 24 * vw,
  },
  categorySelectedText: {
    textAlign: "center",
    fontSize: 18 * vh,
    color: "#4CFF4C",
  },
  categoryUnselectedText: {
    textAlign: "center",
    fontSize: 18 * vh,
    color: "#FFFFFF",
  },
});
